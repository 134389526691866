import { Sidebar } from '@coreui/coreui-pro';

function resetMenuActiveState(sidebarMenuElm) {
  sidebarMenuElm.querySelectorAll('.active').forEach((e) => {
    e.classList.remove('active');
  });
}

(() => {
  'use strict';

  let sidebarMenuElm = document.querySelector('#sidebar');

  if (sidebarMenuElm != null) {
    var menuBar = new Sidebar(sidebarMenuElm);

    var sidebarToggle = document.getElementById('sidebar-toggle');

    sidebarToggle.addEventListener('click', function () {
      menuBar.toggle();
    });
  }
  let sidebarFilterElm = document.querySelector('#filter-bar');
  if (sidebarFilterElm != null) {
    var menuBar = new Sidebar(sidebarFilterElm);

    var sidebarToggle = document.getElementById('filter-bar-toggle');

    sidebarToggle.addEventListener('click', function () {
      menuBar.toggle();
    });
  }

  window.addEventListener('DOMContentLoaded', () => {
    window.addEventListener('htmx:load', () => {
      let current_url = window.location.href;

      if (sidebarMenuElm === null) {
        return;
      }
      resetMenuActiveState(sidebarMenuElm);
      let highestMatch = 0;
      let highestElement = null;

      sidebarMenuElm.querySelectorAll('.nav-link').forEach((e) => {
        e.classList.remove('active');

        let similarity = stringSimilarity(current_url, e.href);
        if (similarity > highestMatch) {
          highestMatch = similarity;
          highestElement = e;
        }
      });

      if (highestElement != null) {
        highestElement.classList.add('active');
      }
    });
  });
})();

function stringSimilarity(str1, str2) {
  /* Calculates the percentage of characters in two strings that match at the same position.
   Adjustments may be needed based on your specific requirements and the nature of the strings you're comparing.

   */
  const len1 = str1.length;
  const len2 = str2.length;
  let matchCount = 0;

  // Assuming strings are of equal length for simplicity
  for (let i = 0; i < len1; i++) {
    if (str1[i] === str2[i]) {
      matchCount++;
    }
  }

  // Calculate percentage similarity
  const similarity = (matchCount / len1) * 100;

  return similarity;
}
