import { showMessage } from './toast.js';

function initDragAndDropUpload() {
  const dropArea = document.getElementById('drop-upload');

  if (dropArea != null) {
    const csrf = dropArea.dataset.csrf;

    ['dragenter', 'dragover', 'dragleave', 'drop'].forEach((eventName) => {
      dropArea.addEventListener(eventName, preventDefaults, false);
    });

    function preventDefaults(e) {
      e.preventDefault();
      e.stopPropagation();
    }

    ['dragenter', 'dragover'].forEach((eventName) => {
      dropArea.addEventListener(eventName, highlight, false);
    });
    ['dragleave', 'drop'].forEach((eventName) => {
      dropArea.addEventListener(eventName, unhighlight, false);
    });

    function handleDrop(e) {
      e.preventDefault();
      e.stopPropagation();
      e.stopImmediatePropagation();
      let dt = e.dataTransfer;
      let files = dt.files;

      handleFiles(files);
    }

    function handleFiles(files) {
      [...files].forEach(uploadFile);
    }

    function uploadFile(file) {
      let url = '/suppliers/price-imports/upload';
      let formData = new FormData();

      formData.append('file', file);
      const tbody = document.getElementById('price-imports');
      console.log(file);
      const fileElement = document.createElement('tr');
      fileElement.insertAdjacentHTML(
        'afterbegin',
        '<td>' +
          file.name +
          '</td><td><div class="spinner-border" role="status">\n' +
          '  <span class="visually-hidden">Loading...</span>\n' +
          '</div></td>',
      );

      tbody.prepend(fileElement);

      const x = document.getElementById('price-import-empty');
      if (x) {
        x.remove();
      }

      fetch(url, {
        method: 'POST',
        body: formData,
        headers: {
          'X-CSRFToken': csrf,
          Accept: 'application/json',
        },
      })
        .then((r) => {
          if (!r.ok) {
            if (r.status === 500) {
              showMessage(
                'error',
                'An error occurred while uploading the file',
              );

              const element = fileElement.querySelector('td:last-child');

              fileElement.querySelectorAll('td').forEach((e) => {
                e.classList.remove('bg-danger');
              });
              element.textContent = 'Errored!';
            }
            r.json().then(function (data) {
              data.errors['file'].forEach((message) => {
                showMessage('error', message);
                const element = fileElement.querySelector('td:last-child');

                fileElement.querySelectorAll('td').forEach((e) => {
                  e.classList.remove('bg-danger');
                });
                element.innerHTML = message;
              });
            });
          } else {
            showMessage('success', 'File uploaded successfully');

            r.text().then((body) => {
              tbody.insertAdjacentHTML('afterbegin', body);
              fileElement.remove();
            });
          }
        })
        .catch((err) => {
          showMessage('error', 'An error occurred while uploading the file');

          const element = fileElement.querySelector('td:last-child');

          fileElement.querySelectorAll('td').forEach((e) => {
            e.classList.remove('bg-danger');
          });
          element.textContent = 'Errored!';
        });
    }

    function highlight(e) {
      dropArea.classList.remove('bg-opacity-25');
      dropArea.classList.add('bg-opacity-50');
    }

    function unhighlight(e) {
      dropArea.classList.remove('bg-opacity-50');
      dropArea.classList.add('bg-opacity-25');
    }

    dropArea.addEventListener('drop', (e) => {
      e.preventDefault();
      e.stopPropagation();
      e.stopImmediatePropagation();
      let dt = e.dataTransfer;
      let files = dt.files;
      handleDrop(e);
    });
  }
}

window.addEventListener('DOMContentLoaded', () => {
  window.addEventListener('htmx:load', () => {
    initDragAndDropUpload();
  });
});
