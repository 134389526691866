import '../sass/project.scss';
import 'htmx.org';

import * as Popper from '@popperjs/core';

window.htmx = require('htmx.org');
window.htmx.config.refreshOnHistoryMiss = true;
window.htmx.config.scrollIntoViewOnBoost = false;

import './includes/theme';
import './includes/sidebar';
import './includes/modal';
import './includes/upload';
import './includes/choices';

import './includes/toast';
